import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Dernières oeillades" />
    <div className="table-responsive">
      <p>
      <span className="black-bold">1. - Nil novi sub sole</span><br/>
        (Ecclésiaste 1, 10)
      </p>
      <p>
      <span className="black-bold">2. - Il y a tellement des gens qui ont dit des choses avant nous</span> (1)<br />
      <span className="black-bold">qu'il faut un damné courage pour le dire après.</span><br />
      <span className="par-starting"></span>Pierre Daninos (Les Nouveaux Carnets du Major Tompson)
      </p>
      <p>
      <span className="black-bold">3. - La littérature de révolte contre l'oppresseur occidental a servi son temps.<br/>
      Les jeunes écrivains actuels doivent s'efforcer de réfléchir sur la condition de l'homme dans les nouvelles structures de l'Afrique...
      à condition que la liberté de la parole et de la plume soit assurée.</span><br/>
      <span className="par-starting"></span>Jean Masamba, Pasteur, EPI Kimpese (Kongo Central)
      </p>
      <p>
      <span className="black-bold">4. - L'ambition n'est tout de même pas le seul mobile qui fasse agir les hommes:<br />
      on peut être révolutionnaire par haine du tyran, par jalousie et même par amour de l'humanité</span><br/>
      <span className="par-starting"></span>André Maurois de l'Académie Francaise (Les Silences du Colonel Bramble)
      </p>
      <p>
      <span className="black-bold">5. - Les problèmes politiques sont les problèmes de tout le monde; les problèmes de 
      tout le monde sont les problèmes politiques</span><br/>
      <span className="par-starting"></span>Tibor Mende (Réflexions sur l'histoire d'aujourd'hui)
      </p>
      <p>
      <span className="black-bold">6. - ...S'il faut creuser notre chemin dans le roc, nous le creuserons
      dans le roc</span><br/>
      <span className="par-starting"></span>Mobutu Sese Seko (Président de la République du Zaïre)
      </p>
      <hr/>
      <p className="date-published">
      (1) L'auteur a remplacé "vous" de Pierre Daninos par "nous"
      </p>
    <br />
    <br />
    </div>
    <Link to="/dernieres-oeillades-preface/">Préface</Link>
  </Layout>
)

export default SecondPage
